// Copyright 2021 andreygorbov
// 
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
// 
//     http://www.apache.org/licenses/LICENSE-2.0
// 
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

export const tripdate = [{
    bigimg: '/expeditions/votto/votto.jpg',
    smallimg: "/s/votto.jpg",
    area:'a',
    name: "Воттоваара",
    header: "ЗА ЭНЕРГИЕЙ СЕВЕРНЫХ ВЕТРОВ",
    h1: "ВОТТОВААРА - гора волшебников",
    link: "/vottovaara",
    video: "k_4EvNCj8RQ",
    invite: "https://t.me/joinchat/6iQgwSYwmwZiNmJi",
    blocks: [{
        name: "Что это за место?",
        bigheder:"Одно из удивительных мест силы",
        text: `
        Мы ездим на Воттоваару с 2007 года. Сам я впервые оказался на этой горе в 2005.
        По сей день это место дарит многим начинающим практическое осознание того, что чудеса случаются.
        Вы много можете услышить историй о горе. Кто-то видел там "существ" или "НЛО". Кто-то трансформировал свою жизнь.
        Кто-то впервые вышел из тела. Это край когда-то был местом поломничества северных Шаманов. Сегодня же это место одно из самых родных для космоэнергетов линии В.А. Петрова`,
        img:"/expeditions/votto/ukko.jpg"
        
    },{
        name: "Как происходит поездка?",
        bigheder:"Это экспедиция с радиальными выходами",
        text: `
        Сама поездка начинается в Санкт-Петербурге с Ладожского воказала мы едем до села Гимолы. И далее добираемся до горы.
        Идем примерно три километра в глубь леса с рюкзаками. 
        На горе разбиваем палаточный лагерь. Каждый живет в своей палатке или у кого-то гостит по договоренности.
        Готовим мы сообща. Есть дежурства где парни рубят дрова, а женщины помогают с кухней.
        `,
        img:"/expeditions/votto/vottosee.jpg"   
    },{
        name: "",
        bigheder:"О мероприятиях",
        text: `
        Каждый день мы отправляемся к разным интересным точкам. И занимаемся там практиками.
        Основа всех наших практик - слышать себя и слышать мир.
        Звучит банально, но я незнаю как описать кратко наши практики.
        Если хотите назовите их медитацией.
        Каждое место на горе стимулирет различные зоны восприятия и высвобождают зажатую энергию.
        И если правильно прислушиваться, то сама природа стимулирует озарения в той или иной сфере жизни.
        В результате едешь домой наполненым с множеством новых идей.
        `,
        img:"/expeditions/votto/vottoles.jpg"   
        }, {
    name: "Как узнать больше?",
    bigheder:"Просто присоединяйтесь к чату!",
    text: `
    - Подключитесь к нашему чату и задайте интересующие вопросы.
    - Послушайте наши эфиры и получите ответы на все вопросы. Как от меня так и от других участников.
    
    Мы модерируем группы и можем отказать в участии, но в целом
    правила простые:
    - Мы не берем с собой навязчивых "Экспертов"
    - Тех кто по какой-то причине считает себя Избранными, Богами, принцессами и т.п. 
    - Людей предпочитающих здоровому общению алкоголь и прочие дурманющие вещества.
    `,
        img:"/expeditions/votto/st.jpg"   
    }
    
    ]

}, {
    bigimg: './title1.jpg',
    smallimg: "/s/hakas.jpg",
    area:'b',
    name: "Хакасия",
    link: "/hakasia",
    header: "За озарением",
    h1: "В край сибирских шаманов",
    video: "noUkYg8XyBo",
    invite: "https://t.me/joinchat/60CUJgiyK5FhZTJi",
    blocks: [{
        name: "Что будет в этой поездке?",
        bigheder:"Мы посетим множество древних мистических мест.",
        text: `
        Которые по сей день привлекают настоящих шаманов. Среди них древние стелы с официальной датировкой более 2000 лет до н.э.
        Места где проходят тропы духов и где шаманы оживляют бубен в первых лучах восходящего солнца.
        Мы увидим священные горы, наполненные энергией реки. И два дня проведем в путешествии на парусных катамаранах по величественному Енисею.
        `,
        img:"/expeditions/hakasia/ded.jpg"
        
    }, {
        name: "Что мы будем делать?",
        bigheder:"Будем учиться видеть тонкие грани",
        text: `
        Кто-то сможет увидеть духов, кто-то ауру. Мы будем стремиться чувствовать жилы драконов, те самые, что описаны в древней китайской геомантии.
        И конечно каждый день будем заниматься нашими классическими практиками. Будем чувствовать чакры и делать много много того от чего мир вокруг становится намного шире и неимоверно ярче.
        `,
        img:"/expeditions/hakasia/edel.jpg"
        
        },{
            name: "",
            bigheder:"",
            text: ``,
            img:"/expeditions/hakasia/kug.jpg"
            
        },
        
        {
        name: "Как все будет организовано?",
        bigheder:"",
        text: `
Все собираются в городе Абакан.Затем мы садимся на автобус и едем около двух часов до живописной долины. Там мы размещаемся на базе. Вариантов проживания два база или палатка рядом. Кушать также можно на базе или привезти продукты с собой.
В основном мы будем ходить пешком до интересных мест, но иногда будем добираться до них на автобусе.
Как уже писал выше одна ночь у нас будет на берегу Енисея в палатках.
        `,
        img:"/expeditions/hakasia/ahtas.jpg"
        
    },{
        name: "Этот маршрут сложный?",
        bigheder:"",
        text: ` 
        Это путешествие подойдет для неподготовленных. Мы не планируем тяжелых переходов. Но легкая нагрузка все таки будет.
        Как оценить? Представьте, что вам надо подняться пешком на 10 этаж? Справитесь? Тогда вы легко справитесь и с нашими маршрутами.
        Энергетика мест мягкая и по мне одна из самых поддерживающих для людей с светлыми намерениями. 
        `,
        img:"/expeditions/hakasia/slojn.jpg"
        
        },{
            name: "Как узнать больше?",
            bigheder:"Просто присоединяйтесь к чату!",
            text: `
            - Подключитесь к нашему чату и задайте интересующие вопросы.
            - Послушайте наши эфиры и получите ответы на все вопросы. Как от меня так и от других участников.
            
            Мы модерируем группы и можем отказать в участии, но в целом
            правила простые:
            - Мы не берем с собой навязчивых "Экспертов"
            - Тех кто по какой-то причине считает себя Избранными, Богами, принцессами и т.п. 
            - Людей предпочитающих здоровому общению алкоголь и прочие дурманющие вещества.
            `,
            img:""   
        }
    
    
    
    ]


    },
    // {
    // smallimg: "/s/baikal.jpg",
    // area:'f',
    // name: "Байкал",
    // link: "/hakas"
    // }
    // ,
    {
    bigimg: '/expeditions/france/g3.jpg',
    smallimg: "/s/france.jpg",
    area:'c',
    name: "Франция",
    link: "/france",
    chatID: '',
    header: "На север Франции",
        h1: "В мир друидов и кельтских мистиков",
        invite: "https://t.me/joinchat/BDLDAAU-rbw2YzI6",
    blocks: [{
        name: "Что за место?",
        bigheder: "Европейская столица мегалитов",
        text: `
        Вы читали сказания о короле Артуре или слышали истории о кельтах и друидах? Собственно в эти края мы и направляемся. Мы увидим тысячи менгиров и множество дольменов. Места силы на все энергоцентры. И конечно потрясающе красивую природу северной Франции.
        Ну и нельзя не сказать за приятные бонусы поездки. Устрицы 6 евро дюжина или совсем бесплатно если собирать их на пляже. Восхитительные десерты и прочие вкусности, иногда заставляют нас шутить, что поездка на места силы Франции непринужденно превращается в гастротур.
        `,
        img: "/expeditions/france/v1.jpg"   
    },{
        name: "Что делаем? ?",
        bigheder: "",
        text: `
        Медитируем, работаем с чакрами на местах силы. Чувствуем жилы дракона, природу и мегалиты. Тренируем восприятие тонкого.
         `,
        img: "/expeditions/france/v2.jpg"   
    },{
        name: "",
        bigheder: "",
        text: ``,
        img: "/expeditions/france/g2.jpg"   
    },{
        name: "Как проходит?",
        bigheder: "",
        text: `
        Прилетаем в Париж.
        Далее либо на своем автобусе, либо  на поезде едем до города Карнак французской провинции Бретань.
        Там заселяемся в виллу рядом с пляжем. Готовим сами, иногда ходим в местные кафешки. К местам ходим и ездим на велосипедах.
        `,
        img: "/expeditions/france/v3.jpg"   
        }, {
                name: "Как узнать больше?",
                    bigheder:"Просто присоединяйтесь к чату!",
                    text: `
                    - Подключитесь к нашему чату и задайте интересующие вопросы.
                    - Послушайте наши эфиры и получите ответы на все вопросы. Как от меня так и от других участников.
                    
                    Мы модерируем группы и можем отказать в участии, но в целом
                    правила простые:
                    - Мы не берем с собой навязчивых "Экспертов"
                    - Тех кто по какой-то причине считает себя Избранными, Богами, принцессами и т.п. 
                    - Людей предпочитающих здоровому общению алкоголь и прочие дурманющие вещества.
                    `,
                img: "/expeditions/france/v4.jpg"   
    }]
},
    {
    bigimg: '/expeditions/turkei/turk_title.jpg',
    smallimg: "/s/turkei.jpg",
    area:'e',
    name: "Турция",
    link: "/turkei",
    chatID:'',
    header: "В Турцию за солнцем",
        h1: "От зороастризма и римских руин до горных духов и кедровых лесов",
        invite: "https://t.me/joinchat/Vlc8KA7vTONjNzQy",
    blocks: [{
        name: "Что за место?",
        bigheder: "Слышали про Олимп и насиляющих его богов?",
        text: `
        В Турции есть горная система Олимп. Где на вершинах растут средиземноморские кедры и пихты. Здесь проходит Ликийская тропа входящая в всемирный топ 10 красивейших пеших маршрутов мира. 
        Когда я впервые увидел этот национальный парк я был поражен силой места (струн тут больше чем на Вотто). Многовековые платаны, пещеры и уединенные пляжи.  Разве не идеальное место для проведения ретритов по нашим практикам?
        `,
        img: "/expeditions/turkei/turkei_see.jpeg"   
    },
    {
        name: "Как будет проходить путешествие?",
        bigheder: "",
        text: `Мы встречаемся в Анталии. Далее отправимся в высокогорный частный отель. 
        Несколько дней будем путешествовать по горному плато.
        Тут столько мест на которых можно “качать” чакры, что даже не сосчитать. Есть  потоки для стимуляции внетелесных состояний. Есть то что оценят товарищи с склонностями к медиумным практикам. Да и собственно бьющимся над очередным прорывом природа может поддать хорошего ускорения.
        Ну а с гор мы спустимся к пляжам. И тут мы сможем посмотреть на всякие исторические места с полигональной кладкой как в Перу, но менее инопланетной. Позанимаемся огненными практиками возле древней Химеры - "Природного вечного огня". Будем практиковать на берегу моря и гулять купаясь в красивых бухтах.`,
        img: "/expeditions/turkei/zedr.jpg"
    },
    {
        name: "",
        bigheder: "",
        text: `
        Тут столько мест на которых можно “качать” чакры, что даже не сосчитать. Есть  потоки для стимуляции внетелесных состояний. Есть то что оценят товарищи с склонностями к медиумным практикам. Да и собственно бьющимся над очередным прорывом природа может поддать хорошего ускорения.
        Ну а с гор мы спустимся к пляжам. И тут мы сможем посмотреть на всякие исторические места с полигональной кладкой как в Перу, но менее инопланетной. Позанимаемся огненными практиками возле древней Химеры - "Природного вечного огня". Будем практиковать на берегу моря и гулять купаясь в красивых бухтах.`,
        img: "/expeditions/turkei/turkei_ark.jpeg" 
        
        }, {
            name: "",
            bigheder: "",
            text: ``,
            img: "/expeditions/turkei/pano.jpg"
        },
    {
        name: "Как узнать больше?",
            bigheder:"Просто присоединяйтесь к чату!",
            text: `
            - Подключитесь к нашему чату и задайте интересующие вопросы.
            - Послушайте наши эфиры и получите ответы на все вопросы. Как от меня так и от других участников.
            
            Мы модерируем группы и можем отказать в участии, но в целом
            правила простые:
            - Мы не берем с собой навязчивых "Экспертов"
            - Тех кто по какой-то причине считает себя Избранными, Богами, принцессами и т.п. 
            - Людей предпочитающих здоровому общению алкоголь и прочие дурманющие вещества.
            `,
        img: "/expeditions/turkei/himer.jpg"   
    }]
},{
    smallimg: "/s/swiss.jpg",
    bigimg: '/expeditions/swiss/g1.jpg',
    area:'d',
    name: "Швейцария",
    link: "/swiss",
    chatID:'',
    header: "Горная сказка",
    h1: "Регион Боденского озера",
    video: "XFuTHTsQUg4",
    invite: "https://t.me/joinchat/vGZGW0iu-Q81ZWNi",
    blocks: [{
        name: "Что за место?",
        bigheder: 'Там где встречаются Германия, Австрия, Швейцария и Княжество Лихтенштейн',
        text: `
        Знаете где можно встретить всех топовых гуру? Обычно у них на родине скажите вы? И в чем то будете правы. Но есть такой регион где можно встретить живущих там перуанских шаманов, индийских браминов, китайских даосов и прочих мастеров. Где-то там когда-то видел красочные сны К Юнг, а Месмер писал свою работу о природном “магнетизме”, легшим в основу современного гипноза и трансовых техник. Там была построена огромная библиотека для эзотериков. Да и еще что там только не происходило.
И в нашем путешествии мы увидим этот край под своим углом.  
Мы посетим горный массив Аппенцель. Поселимся в высокогорном отеле и будем практиковать. Посетим интересные места и наполнимся впечатлениями и энергией на многие месяцы.
`,
        img: "/expeditions/swiss/v1.jpg"   
    },{
        name: "Что скажете о этих местах как о местах силы?",
        bigheder: "",
        text: `
        Да все тут прекрасно. Есть все для практик. Мощная энергетика на все чакры, духи леса и незабываемая красота природы. Долина водопадов, высокогорные озера, что еще нам надо для зарядки своих батареек?
`,
        img: "/expeditions/swiss/v2.jpg"   
    },{
        name: "",
        bigheder: "",
        text: ``,
        img: "/expeditions/swiss/g2.jpg"   
    },{
        name: "Как проходит?",
        bigheder: "",
        text: `Собираемся в аэропорту Цюриха. Садимся в автобус и едем до горного хребта. Поднимаемся на подъемнике и заселяемся в горный отель. Ну, а дальше гуляем и занимаемся. Иногда спускаемся с гор чтобы доехать до следующих. Если будет настроение заезжаем в Лихтенштейн. Маленькое княжество с удивительной историей. Или быть может едем в высокогорную Фалеру. Может и делаем все вместе.  
        `,
        img: "/expeditions/swiss/v3.jpg"   
    },{
        name: "Как узнать больше?",
            bigheder:"Просто присоединяйтесь к чату!",
            text: `
            - Подключитесь к нашему чату и задайте интересующие вопросы.
            - Послушайте наши эфиры и получите ответы на все вопросы. Как от меня так и от других участников.
            
            Мы модерируем группы и можем отказать в участии, но в целом
            правила простые:
            - Мы не берем с собой навязчивых "Экспертов"
            - Тех кто по какой-то причине считает себя Избранными, Богами, принцессами и т.п. 
            - Людей предпочитающих здоровому общению алкоголь и прочие дурманющие вещества.
            `,
        img: "/expeditions/swiss/v4.jpg"   
    }
    ]
    
    }
//     , {
//     smallimg: "/s/kola.jpg",
//     area:'g',
//     name: "Кольский",
//     link: "hakas"
// }
]