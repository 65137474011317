// Copyright 2021 andreygorbov
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
import React, { useContext, useRef} from "react";
import styled from "styled-components";
import { EditLesson } from "./editlesson";
import { LessonBody } from './lessonbody'
import { Button } from './ui/button'
import { delLesson } from './net'
import axios from 'axios'
import { API_URL} from '../var'
import { useOnClickOutside } from '../menuhook'
import {StoreContext} from '../store/store'

const Wrapper = styled.div`
display: flex;
color:${({ theme })=> theme.text};
`
const LessonsList = styled.div`
z-index:100;
display: flex;
flex-direction: column;
padding: 1.5rem 1.5rem 0.375rem;
background-color:${({theme})=> theme.leftmenu};
width: 300px;
position: ${({ open }) => open ? 'relative' : 'absolute'};
transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(-100%)'};
@media screen and (max-width: 768px) {
    position: absolute;
  }
`


const MenuText = styled.p`
display: flex;
&:hover {
color: ${({theme})=> theme.hover};
font-weight: 400;
text-decoration: underline;
}
`
const MenuItem = styled.div`
display: flex;
justify-content: space-between;
bottom: 0px;
box-sizing: border-box;
cursor: pointer;
font-family: 'Ubuntu';
font-weight: 400;
left: 0px;
line-height: 21.45px;
list-style: outside none none;
outline: none;
position: relative;
right: 0px;
text-decoration: none;
top: 0px;
transition: color 0.075s ease-in-out 0s;

`

const Div = styled.div`
display:flex;
`


export const Lessons = ({ auth, setOpen, fetchLesson, open, node2}) => {
  const [{lessons, select, courses, courseIndex, newlesson}, dispatch] = useContext(StoreContext);

  let courseId = courses[courseIndex].id

  const node = useRef();
  useOnClickOutside(node, () => setOpen(false));

  const deleteLesson = async (item, key) => {
    
    if (item.image) {
      let formData = new FormData();
      let data = JSON.stringify({
        idc: auth.idc,
        lessonId: item?.id,
        filetype: "image",
        old: item.image.split('/')[2]
      });
    formData.append('data', data);
    
      await axios.post(API_URL+'/upload', formData).catch(e => console.log(e))
    }
    if (item.audio) {
      let formData = new FormData();
      let data = JSON.stringify({
        idc: auth.idc,
        lessonId: item?.id,
        filetype: "audio",
        old: item.audio.split('/')[2]
      });
    formData.append('data', data);
    
      await axios.post(API_URL+'/upload', formData).catch(e => console.log(e))
    }


  
    await delLesson({
      lessonId: item.id,
      idc: auth.idc
  }).then( () => {
    dispatch({ type: 'SET_LESSON_ID', payload: 0 })
    dispatch({ type: 'SET_SELECT', payload:NaN })
    fetchLesson({courseId})
  }
  )
    
  
    
}



var lessonsmenu
  if (lessons) {

    lessonsmenu = lessons.map((item, key) => {

      return (
        <MenuItem key={`${key}${Date.now()}`} onClick={() => dispatch({ type: 'SET_LESSON_ID', payload: key })}>
          <MenuText>{item.name}</MenuText>
          {auth.role === "admin" && <Div><Button icon="edit" onClick={() => dispatch({ type: 'SET_SELECT_TO_EDIT', payload: { key: key, lesson: item } } )} />
            <Button margin='5px' icon="del" onClick={() => deleteLesson(item, key)}> </Button>
          </Div>}
        </MenuItem>
      )
    })
  } else return (<></>)

  
return  (
  <Wrapper>
<LessonsList ref={node2} open={open}> 
      {lessonsmenu}
      {auth.role === "admin" && <Button text="Урок" icon="add" iconsize="1.1em" size="1.3rem" onClick={() => dispatch({
        type: 'SET_NEWLESSON', payload: {
          newlesson: true,
          queue: lessons.length!==0?lessons[lessons.length-1].queue + 10:10
        }})} />}
  </LessonsList>
    {lessons && lessons.length !==0 && (select!==select) && !newlesson && <LessonBody  open={open} />}
    {(newlesson || select === select) && <EditLesson fetchLesson={fetchLesson}  open={open}   auth={auth} />}

 </Wrapper>

)
  
}