// Copyright 2021 andreygorbov
// 
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
// 
//     http://www.apache.org/licenses/LICENSE-2.0
// 
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import React, {useState, useContext, useEffect} from 'react';
import { Input } from './ui/input'
import {Button} from './ui/button'
import styled from 'styled-components'
import axios from 'axios';
import { Text } from './ui/text';
import { API_URL } from '../var'
import {StoreContext} from '../store/store'
const Wrapper = styled.div`
display:flex;
flex-wrap: wrap;
margin: 2rem;
padding: 2em;
box-sizing: border-box;
justify-content: space-evenly;
`
const Item = styled.div`
margin: 1rem;
display:flex;
flex-grow:1;
width:350px;
justify-content: space-between;
align-items: baseline;
`
const List = styled.div`
margin: 10px;
background-color:${({theme})=> theme.lessonbody};
`
const InputW = styled.div`
margin: 10px;
background-color:${({theme})=> theme.lessonbody};
box-sizing:border-box;
padding: 10px;
`

const Textarea = styled.textarea`
font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 200;
box-sizing:border-box;
min-width: 300px;
border:none;
margin: 5px;
background-color:${({theme})=> theme.input.bg};
padding: 1em;
&:focus {
border: solid 0.5px ${({theme})=> theme.body};
background-color:${({theme})=> theme.input.hover};
}
&::placeholder {
color: ${({theme})=> theme.input.placeholder};
}
`




export const AddSubscriptions = ({auth}) => {
const [list, setList] = useState([])
    const [{ } ,dispatch] = useContext(StoreContext);
    
    const [fields, setFields] = useState({
    name: "",
    cancel_url:"",
    success_url:"",
    description:"",
    inviteLink:"",
    priceId:"",
    privat_chat:"",
    type: 'addSub',
        idc: auth.idc,
        outSum: 0
    }
    )

var cansend = fields.description !== "" &&
fields.inviteLink !== "" &&
fields.priceId !== "" &&
fields.privat_chat !== "" &&

    fields.name !== ""

  

    useEffect(() => {
        async function getBySubscription() {
            await axios.post(API_URL+'/data', {
                idc: auth.idc,
                type:"getSub"
              }).then(res => setList(res.data.subscriptions))
        }
        getBySubscription(auth.idc)
        
    }, [auth.idc])
    
  const addSubscription = async () => {
        if (cansend) {
            await axios.post(API_URL+'/data', fields).then(
             ()=>   dispatch({ type: 'SET_CORSE_EDITOR_SELECTOR', payload:  'course'})
            ).finally(async ()=> await axios.get(API_URL+'/subreload'))
            
        }
    }

    const delSubscription = async (id, key) => {
        
        await axios.post(API_URL+'/data', {
                idc: auth.idc,
                type: "delSub",
                id
        }).finally(
         ()=>  setList(list.splice(key+1, 1))  
        )
        
    }


    const slist = list.map((item, key) => {
        return <Item key={key + `${Date.now()}` }>
            <Text>{item.description}</Text>
            <Button icon="del" onClick={()=> delSubscription(item.id, key)}/>
     </Item>   
    })
    
    return (
        <Wrapper>

            <InputW>
            <Input type="text" placeholder="Имя подписки" value={fields.name} width="300px" onChange={e => setFields({...fields, name: e.target.value}) }/>
         
            <Textarea  rows="10" type="text" placeholder="Описание" defaultValue={fields.description } width="300px" onChange={e => setFields({...fields, description: e.target.value}) }/>
            <Input type="text" placeholder="Инвайт Линк" value={fields.inviteLink } width="300px" onChange={e => setFields({...fields, inviteLink: e.target.value}) } />
            <Input type="text" placeholder="АйДи чата" value={fields.privat_chat } width="300px" onChange={e => setFields({...fields, privat_chat: e.target.value}) }/>
            <Input type="text" placeholder="Прайс ID Stripe" value={fields.priceId } width="300px" onChange={e => setFields({...fields, priceId: e.target.value}) }/>
            <Input type="text" placeholder="URL Success" value={fields.success_url } width="300px" onChange={e => setFields({...fields, success_url: e.target.value}) }/>
                <Input type="text" placeholder="URL Cancel " value={fields.cancel_url} width="300px" onChange={e => setFields({ ...fields, cancel_url: e.target.value })} />
                <Input type="number" placeholder="Сумма в Евро" value={fields.outSum } width="300px"onChange={e => setFields({...fields, outSum: e.target.value}) }/>
            <Button disabled={!cansend} margin="1rem 1rem 1rem 1rem" onClick={()=> addSubscription()}>Создать подписку</Button>
            <Button margin="1rem 1rem 1rem 1rem" onClick={()=> dispatch({ type: 'SET_CORSE_EDITOR_SELECTOR', payload:  'course'})}>Отменить</Button>
            </InputW>
            <List>
            {slist}
            </List>
           
    </Wrapper>    
    )
}