

// Copyright 2021 andreygorbov
// 
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
// 
//     http://www.apache.org/licenses/LICENSE-2.0
// 
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import React from 'react';
import styled from 'styled-components'
import { Video } from "../components/video"
import { Toggle} from '../components/ui/toggle'


const Body = styled.div`
display: flex;
flex-direction: column;
flex-grow:1;
`

const Screen1 = styled.div`
position: relative;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
height: 100vh;
flex-grow:1;
background-repeat: no-repeat;
background-position: center;
background-size: cover;
background-image: url(${({ img }) => img && img});

`
const Overley = styled.div`
display: flex;
background-color: rgba(0,0,0,0.4);
flex-grow:1;
width:100%;
justify-content: center;
align-items: center;
`
const Screen2 = styled.div`
margin: 0 0 2rem 0;
display: flex;
justify-content: center;
align-items: center;
flex-grow:1;
flex-wrap:wrap;
box-sizing: border-box;
`
const Screen3 = styled.div`
margin: 0;
display: flex;
justify-content: center;
align-items: center;
box-sizing: border-box;
div {
    height: inherit;
    margin: 0;
    position: relative;
    box-sizing: border-box;
    justify-content: center;
    display: flex;
    width: 100%;
  }
  
`

const Sect = styled.div`
width: 60%;
display: flex;
flex-direction: column;
align-items: left;
box-sizing: border-box;
`
const Image = styled.div`
height:550px;
min-width: ${({ big }) => big ? "100%" : "450px"};
background-attachment: fixed;
background-repeat: no-repeat;
background-position: ${({ revers }) => revers===true ? 'left' : 'right'};
background-size: ${({big})=> big?"cover":"contain"};
background-image: url(${({img})=>  img});
box-sizing: border-box;
background-position-x:${({ revers }) => revers?"10%":"90%"};
@media screen and (max-width: 1000px) {
    //width:100%;
    background-position: center;
    background-position-x:${({ revers }) => revers?"5%":"95%"};
  }
  @media screen and (max-height: 400px){
    background-attachment: scroll;
    background-position-x: center;
    background-position: center;
  }
  @media screen and (max-width: 450px) {
    min-width: 100%;
    background-position: center;
  }
  @media screen and (max-width: 800px) {
    background-position-x: center;
    background-attachment: fixed;
  }
  @media  (max-width: 1100px) {
    background-position-x: ${({ revers }) => revers===true ? 'left' : 'right'};
  }
`
const Dobro = styled.div`
position:relative;
padding: 0 0 4rem 0;
margin: 0 3rem;
display: flex;
min-width:50%;
flex-direction: column;
box-sizing: border-box;
height: fit-content;
line-height: 1.4;
text-align:  ${({ revers }) => revers ? 'right' : 'left'};
align-items: ${({ revers }) => revers ? 'flex-end' : 'flex-start'};
@media  (max-width: 1100px) {
margin: 0;
margin: 0 1rem;
min-width: unset;
  }
`
const Gradient = styled.div`
position: absolute;
display: flex;
bottom: 0px;
    width: 100%;
height: 10vh;
left: 0px;
background: linear-gradient(${({theme})=> theme.gradient});

`
const Heder = styled.p`
width:${({revers})=> revers && "min-content"};
white-space: pre-line;
font-family: 'Ubuntu';
font-style: normal;
font-weight: 800;
font-size: 1em;
letter-spacing: 6px;
text-transform: uppercase;
color: ${({theme, color})=> color?color:theme.heder};
@media screen and (max-width: 1000px) {
    font-size: 0.8em;
  }
`

const P = styled.p`
white-space: pre-line;
font-family: 'Ubuntu';
font-style: normal;
font-weight: normal;
font-size: 1.1em;
line-height: 32px;
color: ${({theme})=> theme.text};
@media screen and (max-width: 320px){
  font-size: ${({size}) =>size?size:"0.8em"};
}
@media screen and (max-width: 1000px){
  font-size: ${({size}) =>size?size:"1em"};
}
`

const H1 = styled.p`
white-space: pre-line;
margin: 0;
font-family: 'Lora';
font-style: normal;
font-weight: 600;
font-size: 3em;
text-transform: uppercase;
color: ${({theme, color})=> color?color:theme.text};
@media screen and (max-width: 1000px) {
    font-size: 1.7em;
  }
@media screen and (max-width: 768px) {
    font-size: 1.5em;
  }
`
const H2Line = styled.div`
background-color: ${({theme, color})=> color?color:theme.heder};
height: 2px;
width: 30px;
margin: 0 1em 0 0;
`
const DivG = styled.div`
display: flex;
align-items: center;
`
const Block = styled.div`
width: 80%;
display:flex;
align-items:center;
margin-bottom: 2rem;
flex-direction: ${({ revers }) => revers ? 'row-reverse' : 'row'};
@media screen and (max-width: 800px) {
flex-direction: column;
align-items: center;
justify-content: center;
padding: 0 20px;
  }
@media screen and (max-width: 1200px) {
  width: 100%;
}
`
const Button = styled.div`
width: 100%;
text-align: center;
align-items: center;
box-sizing: border-box;
justify-content: center;
display: flex;
margin: 4rem auto;
cursor: pointer;
padding: 11px 20px;
//border: solid 1px #fff;
&:hover{
  background-color: ${({theme})=> theme.button.hover};
}
`

const Screen4 = styled.div`
height: 100vh;
display:flex;
justify-content: center;
align-items: center;
`
const blocks = [ {
    bigheder:'Willkommen bei Yelena Sundaria.',
    name: 'Über mich...',
    text: `
Ich blicke auf eine mehr als 14jährige Erfahrung in esoterischen Praktiken zurück. Meine Qualifikationen umfassen
- eine Ausbildung zur Jyotish-Meisterin in der Guru Parampara (Lehrer-Schüler-Nachfolge) von Sanjay Rath
- Lehrerin der Kosmoenergetik (Magistergrad)
- Usui Reiki Rio Meisterin der klassischen japanischen Linie der Wissensübergabe

Mein Angebot erstreckt sich von Jyotish-Geburtschart-Analysen (tiefgründiger und detaillierter als eine Horoskop-Analyse der westlichen Astrologie) sowie Aura- und Chakra-Scan über Aura-Heilung bis hin zu Kosmoenergetik-Sitzungen und Reiki-Meditationen, welche ich weltweit anbiete.
    `,
    img:'./images/alena.jpg',
    },
    {
        bigheder:'Jyotish mit mir...',
        name: '',
        text: `
Jyotish kommt von «Jyoti» (sanskr.) und bedeutet «Licht». 
Es birgt das uralte Wissen über die Erschaffung der Welt und den Zusammenhang von allem Lebenden, bspw. darüber, wie die Planeten auf die Menschen einwirken. 

Dieses Wissen gibt exakte Antworten auf alle Fragen, die den Menschen seit Jahrtausenden interessieren.

Die Ursachen von Glück, Liebe, Gesundheit, Krankheit, Reichtum und Armut, Begabungen und s.w.
Das wichtigste Ziel von Jyotish besteht darin ein Verständnis für das eigene Schicksal zu bekommen.

Jyotish spricht über die Notwendigkeit von menschlichen und moralischen Werten. Es fordert das Loslassen von Illusionen und die Hinwendung zu dem Ewigen.
Jyotish ist mit den anderen vedischen Lehren verbunden: Ayurveda, Yoga, Vastushastra (Vedische Architektur), Atharvaveda (Wissen über Mantras und Tantra) etc.  

Vedische Astrologie zählt in Indien zu den Naturwissenschaften. 
Ich studiere seit 14 Jahren Jyotish und bin Schülerin einer uralten Parampara - der in Indien vertretenen Linie von Sanjay Rath. 

Seit 7 Jahren betreue ich zahlreiche glückliche Kunden rund um den Globus, die eine hochqualitative Beratung erhalten haben. Ich biete einzelne Beratungen, Seminare und Schulungen in Jyotish für Interessierte weltweit.
`,
        img:'./images/jyotish.jpg',
    },{
      name:'',
      bigheder: 'Kosmoenergetik mit mir...',
      text: `
      Kosmoenergetik kommt vom griechischen Wort „Kosmos“, was mit „Ordnung“, „Weltordnung“, aber auch „Schmuck“, „Glanz“, „Ehre“, „militärische Ordnung“, „staatliche Ordnung“ oder „Verfassung“ übersetzt werden kann.
      Kosmos bezeichnet aber auch das Universum, das gesamte Weltall.
      Damit sollte die Allgegenwärtigkeit dieser Methode beschrieben werden.
      Der Ursprung der Kosmoenergetik liegt in Uzbekistan – entstanden als ein weißes Tantra, welche zum geistigen, körperlichen und mentalen Gleichgewicht sowie Gesundheit führen sollte.
      Der Erfasser - nicht der Erfinder, da das weiße Tantra, welches wir heute als Kosmoenergetik kennen, schon tausende Jahre vor ihm existierte – V.A. Petrov, geboren in Taschkent, Uzbekistan äußerte sich zur Kosmoenergetik wie folgt:
      „Die Kosmenergetik wurde als eine Express-Methode zur geistigen Selbstentwicklung durch die Stärkung der Aura eines Menschen entwickelt.“
      Sie wurde als eine effektive Methode zusammengefasst, um dem Menschen in schwierigsten Zeiten zu helfen.
      Die Kosmoenergetik besteht aus einer großen Anzahl von heilenden Frequenzen, die stets von einem MAGISTER (Lehrer) an seine Schüler weitergegeben werden kann.
       Seit 10 Jahren bin ich Schülerin eines direkten Nachfolgers des Zusammenfassers der Kosmoenergetik und mehr als 7 Jahre verbrachte ich mit intensiver Arbeit an Kraftorten mit Menschen, die energetische Hilfe brauchten.
      Als MAGISTERIN mit großer Erfahrung biete ich sowohl Einzel- als auch Gruppencoachings an, ebenso Sitzungen und Mediationen zur Selbsthilfe und energetischen Heilung.
      `,
      img:'./images/kosmo.jpg',
      
  },{
    bigheder:'Reiki mit mir...',
    name: '',
  text: `
Reiki (rei = Geist,   Handauflegen) 
- im 19. Jh. in Japan vom buddhistischen Mönch und Gelehrten Mikao Usui wiederentdeckt und etabliert

Ich habe einen MEISTER Grad und das vierte Meistersymbol im klassischen USUI REIKI RIOHO. Unsere Linie hat keinen Bezug zur westlichen Linie von Reiki und kommt von den Japanischstämmigen Schülern. Dies heilende Energie des Reiki steht jederzeit zur Verfügung und kann auch zur Behandlung anderer Menschen genutzt werden. Ich biete wöchentliche Reiki Meditationen, Einzel- und Gruppen Coachings, Ausflüge und Sitzungen in Reiki.  
    `,
    img:'./images/reiki.jpg',
}]


const H2 = ({children, color}) => (
  <DivG><H2Line color={ color}/> <Heder color={ color} bold='400'>{children}</Heder></DivG>
)

const InfoBlock = ({ img, heder, text, bigheder, revers }) => {
  const bigImage = heder!=="" || bigheder!=="" || text!==""

  return (
<Screen2> <Block revers={revers}>
      {bigImage && <Dobro revers={revers}>
        {heder && <H2 revers={revers}>{heder}</H2>}
        {bigheder && <H1>{bigheder}</H1>}
        {text && <P>{text}</P>}
      </Dobro>}
        
      {img && <Image big={!bigImage} img={img} revers={revers} />}
  </Block>
  </Screen2>
  )
}
  


export const Oshkole = ({setSelectedTheme}) => {

  const blocklist = blocks.map((block, key) => {
    const even = n => !(n % 2);
    const side = even(key)
    return (
    <InfoBlock
      key={Date.now()+`${key}`}
      revers={side}
      heder={block.name}
      text={block.text}
      bigheder={block.bigheder}
      img={block.img}
    />
    )})
    return (
        <Body>
        <Toggle id={`${Date.now()}`}  setSelectedTheme={setSelectedTheme} color="#fff"/>
        <Screen1 img={'./images/nisarg_unsplash.jpg'}>
        <Gradient />
          <Overley>
                
                
                <Sect>
              <H2 color="#FBD784">Esoterische Schule von Yelena Sundaria</H2>
              <H1 color="#fff"margin="2rem 0 2rem 0">Ich nehme Sie mit in die Geheimnisse der Spiritualität.</H1>
                    
                </Sect>

            </Overley>
            </Screen1>
            
           
        {blocklist}

        {/* { <Screen3>
          <Video video='SnKy5J0n2Ak' height="500px" width="100%" />
        </Screen3>} */}

            <a href='https://t.me/sundariagespraeche'>
            <Screen4>
          <Button>
            <svg xmlns="http://www.w3.org/2000/svg"
              width="60px"
              height="60px"
              viewBox="0 0 240 240">
      <defs>
        <linearGradient id="a" x1="0.667" x2="0.417" y1="0.167" y2="0.75">
          <stop offset="0" stopColor="#37aee2"></stop>
          <stop offset="1" stopColor="#1e96c8"></stop>
        </linearGradient>
        <linearGradient id="b" x1="0.66" x2="0.851" y1="0.437" y2="0.802">
          <stop offset="0" stopColor="#eff7fc"></stop>
          <stop offset="1" stopColor="#fff"></stop>
        </linearGradient>
      </defs>
      <circle cx="120" cy="120" r="120" fill="url(#a)"></circle>
      <path
        fill="#c8daea"
        d="M98 175c-3.888 0-3.227-1.468-4.568-5.17L82 132.207 170 80"
      ></path>
      <path
        fill="#a9c9dd"
        d="M98 175c3 0 4.325-1.372 6-3l16-15.558-19.958-12.035"
      ></path>
      <path
        fill="url(#b)"
        d="M100.04 144.41l48.36 35.729c5.519 3.045 9.501 1.468 10.876-5.123l19.685-92.763c2.015-8.08-3.08-11.746-8.36-9.349l-115.59 44.571c-7.89 3.165-7.843 7.567-1.438 9.528l29.663 9.259 68.673-43.325c3.242-1.966 6.218-.91 3.776 1.258"
      ></path>
    </svg>

            <P style={{marginLeft: "20px"}} size="1em">Hier geht es zum Chat!</P>
</Button>
</Screen4></a>

        
        </Body>  
)
}