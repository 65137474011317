

import React from 'react';
import styled from 'styled-components'
import { Toggle} from '../components/ui/toggle'


const Body = styled.div`
display: flex;
flex-direction: column;
flex-grow:1;
`

const Screen1 = styled.div`
position: relative;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
height: 100vh;
flex-grow:1;
background-repeat: no-repeat;
background-position: center;
background-size: cover;
background-image: url(${({ img }) => img && img});

`
const Overley = styled.div`
display: flex;
background-color: rgba(0,0,0,0.4);
flex-grow:1;
width:100%;
justify-content: center;
align-items: center;
`
const Screen2 = styled.div`
margin: 0 0 2rem 0;
display: flex;
justify-content: center;
align-items: center;
flex-grow:1;
flex-wrap:wrap;
box-sizing: border-box;
`
const Screen3 = styled.div`
margin: 0;
display: flex;
justify-content: center;
align-items: center;
box-sizing: border-box;
div {
    height: inherit;
    margin: 0;
    position: relative;
    box-sizing: border-box;
    justify-content: center;
    display: flex;
    width: 100%;
  }
  
`

const Sect = styled.div`
width: 60%;
display: flex;
flex-direction: column;
align-items: left;
box-sizing: border-box;
`
const Image = styled.div`
height:550px;
min-width: ${({ big }) => big ? "100%" : "450px"};
background-attachment: fixed;
background-repeat: no-repeat;
background-position: ${({ revers }) => revers===true ? 'left' : 'right'};
background-size: ${({ big }) => big ? "cover" : "contain"

};
background-image: url(${({img})=> img});
box-sizing: border-box;
background-position-x:${({ revers }) => revers?"10%":"90%"};
@media screen and (max-width: 1000px) {
    //width:100%;
    background-position: center;
    background-position-x:${({ revers }) => revers?"5%":"95%"};
  }
  @media screen and (max-height: 400px){
    background-attachment: scroll;
    background-position-x: center;
    background-position: center;
  }
  @media screen and (max-width: 450px) {
    min-width: 100%;
    background-position: center;
  }
  @media screen and (max-width: 800px) {
    background-position-x: center;
    background-attachment: fixed;
  }
  @media  (max-width: 1100px) {
    background-position-x: ${({ revers }) => revers===true ? 'left' : 'right'};
  }
`
const Dobro = styled.div`
position:relative;
padding: 0 0 4rem 0;
margin: 0 3rem;
display: flex;
min-width:50%;
flex-direction: column;
box-sizing: border-box;
height: fit-content;
line-height: 1.4;
text-align:  ${({ revers }) => revers ? 'right' : 'left'};
align-items: ${({ revers }) => revers ? 'flex-end' : 'flex-start'};
@media  (max-width: 1100px) {
margin: 0;
margin: 0 1rem;
min-width: unset;
  }
`
const Gradient = styled.div`
position: absolute;
display: flex;
bottom: 0px;
    width: 100%;
height: 10vh;
left: 0px;
background: linear-gradient(${({theme})=> theme.gradient});

`
const Heder = styled.p`
width:${({revers})=> revers && "min-content"};
white-space: pre-line;
font-family: 'Ubuntu';
font-style: normal;
font-weight: 800;
font-size: 1em;
letter-spacing: 6px;
text-transform: uppercase;
color: ${({theme, color})=> color?color:theme.heder};
@media screen and (max-width: 1000px) {
    font-size: 0.8em;
  }
`

const P = styled.p`
white-space: pre-line;
font-family: 'Ubuntu';
font-style: normal;
font-weight: normal;
font-size: 1.1em;
line-height: 32px;
color: ${({theme})=> theme.text};
@media screen and (max-width: 320px){
  font-size: ${({size}) =>size?size:"0.8em"};
}
@media screen and (max-width: 1000px){
  font-size: ${({size}) =>size?size:"1em"};
}
`

const H1 = styled.p`
white-space: pre-line;
margin: 0;
font-family: 'Lora';
font-style: normal;
font-weight: 600;
font-size: 3em;
text-transform: uppercase;
color: ${({theme, color})=> color?color:theme.text};
@media screen and (max-width: 1000px) {
    font-size: 1.7em;
  }
@media screen and (max-width: 768px) {
    font-size: 1.5em;
  }
`
const H2Line = styled.div`
background-color: ${({theme, color})=> color?color:theme.heder};
height: 2px;
width: 30px;
margin: 0 1em 0 0;
`
const DivG = styled.div`
display: flex;
align-items: center;
`
const Block = styled.div`
width: 80%;
display:flex;
align-items:center;
margin-bottom: 2rem;
flex-direction: ${({ revers }) => revers ? 'row-reverse' : 'row'};
@media screen and (max-width: 800px) {
flex-direction: column;
align-items: center;
justify-content: center;
padding: 0 20px;
  }
@media screen and (max-width: 1200px) {
  width: 100%;
}
`
const Button = styled.div`
width: 100%;
text-align: center;
align-items: center;
box-sizing: border-box;
justify-content: center;
display: flex;
margin: 4rem auto;
cursor: pointer;
padding: 11px 20px;
//border: solid 1px #fff;
&:hover{
  background-color: ${({theme})=> theme.button.hover};
}
`

const Screen4 = styled.div`
height: 100vh;
display:flex;
justify-content: center;
align-items: center;
`
const blocks = [ {
    bigheder:'',
    name: '',
  text:`
Melden Sie sich am besten gleich zu meinem kostenlosen Telegram Chat an und erhalten Sie einen umfassenden Überblick über alle Themen und Angebote. 

Hier werden nicht nur Lehrgespräche zu den Themen Jyotish, Kosmoenergetik, Reiki, Kraftorte, Meditationen, Energiearbeit  geführt, sondern auch alle Fragen zu meinen Angeboten beantwortet, Scan - Verlosungen - und Meditationen durchgeführt, Fragen zur vedischen Astrologie behandelt und vieles mehr!  
` 
   ,img:'',
    },
    {
        bigheder:'',
        name: '',
      text: `
Sie haben noch nie meditiert  oder Sie möchten mehr über die Meditation erfahren? Dann nutzen Sie die Möglichkeit, an einem Meditationskurs vom Beginner- bis zum Fortgeschrittenen-Level teilzunehmen.
Mit der Anmeldung haben Sie Zugang zum theoretischen Kurs und bekommen die Möglichkeit, ein Mal in der Woche bei Online Meditationen mitzumachen. Mehr dazu können Sie in meinem Telegram-Bot erfahren.
      `,
        img:'./images/medit.jpg',
  },
 {
        bigheder:'',
        name: '',
      text: `
Wenn Sie aber bereits Erfahrung in Meditationen oder in Energiearbeit gesammelt haben,  erweitern Sie Ihr Wissen durch eine Schulung in Kosmoenergetik.
Kosmoenergetik ist eine energetische Heilmethode. Es braucht immer eine persönliche Übergabe von heilenden und die Intuition fördernden Frequenzen, die immer von einem Lehrer (Magister) durchgeführt wird. Kosmoenergetik gilt als die effektivste Methode, die zu einer inneren Ausgeglichenheit, Regeneration der Aura und der Chakren und damit verbundenen geistigen Wachstum verhelfen soll. 
      `,
   img: './images/ellkosmo.jpg',
   
        
  },  {
    bigheder:'',
      name:'',
      text: `
Möchten Sie mehr über Schicksal und Karma erfahren, können Sie sich für meine Jyotish Kurse registrieren. In diesen biete ich ein komplettes Programm von Seminaren zum Thema Vedische Astrologie aus der Sicht einer alten Parampara Indiens. Sie bekommen von mir persönliche wöchentliche Online-Seminare inklusive der Analyse von Charts. Daneben erhalten Sie kursbegleitende Hausaufgaben, die wir während unserer Seminare nach und nach durchgehen werden. Und Sie bekommen einen Zugang zum Archiv der bereits durchgeführten Seminare, die Sie jederzeit aufrufen und abhören können.
      `,
      img:'./images/jiot.jpg',
      
  }, {
    bigheder:'',
      name:'',
      text: `
Bei Interesse können Sie auch eine Einweihung in Usui Reiki Rioho erhalten und an meinen Online Reiki Meditationen teilnehmen. 
In einem Tagesseminar erhalten Sie die Einweihung in das erste Reiki Symbol mit der dazugehörigen Theorie und den Reiki Übungen. Anschließend an das Seminar haben Sie die Möglichkeit, einen Monat kostenlos bei Online Reiki Kreisen mitzumachen.
Aufbauend darauf können Sie bei mir die Schulungen bis zum Reiki Meistergrad absolvieren.
      `,
      img:'./images/reiki2.jpg',
      
  }]


const H2 = ({children, color}) => (
  <DivG><H2Line color={ color}/> <Heder color={ color} bold='400'>{children}</Heder></DivG>
)

const InfoBlock = ({ img, heder, text, bigheder, revers }) => {
  const bigImage = heder!=="" || bigheder!=="" || text!==""

  return (
<Screen2> <Block revers={revers}>
      {bigImage && <Dobro revers={revers}>
        {heder && <H2 revers={revers}>{heder}</H2>}
        {bigheder && <H1>{bigheder}</H1>}
        {text && <P>{text}</P>}
      </Dobro>}
        
      {img && <Image big={!bigImage} img={img} revers={revers} />}
  </Block>
  </Screen2>
  )
}
  


export const Programs = ({setSelectedTheme}) => {

  const blocklist = blocks.map((block, key) => {
    const even = n => !(n % 2);
    const side = even(key)
    return (
    <InfoBlock
      key={Date.now()+`${key}`}
      revers={!side}
      heder={block.name}
      text={block.text}
      bigheder={block.bigheder}
      img={block.img}
    />
    )})
    return (
        <Body>
        <Toggle id={`${Date.now()}`}  setSelectedTheme={setSelectedTheme} color="#fff"/>
        <Screen1 img={'./images/para.jpg'}>
        <Gradient />
          <Overley>
                
                
                <Sect>
             
              <H1 color="#fff"margin="2rem 0 2rem 0">Wie fange ich an?</H1>
                    
                </Sect>

            </Overley>
            </Screen1>
            
           
        {blocklist}
      

        

        <a href='https://t.me/sundariagespraeche'>
            <Screen4>
          <Button>
            <svg xmlns="http://www.w3.org/2000/svg"
              width="60px"
              height="60px"
              viewBox="0 0 240 240">
      <defs>
        <linearGradient id="a" x1="0.667" x2="0.417" y1="0.167" y2="0.75">
          <stop offset="0" stopColor="#37aee2"></stop>
          <stop offset="1" stopColor="#1e96c8"></stop>
        </linearGradient>
        <linearGradient id="b" x1="0.66" x2="0.851" y1="0.437" y2="0.802">
          <stop offset="0" stopColor="#eff7fc"></stop>
          <stop offset="1" stopColor="#fff"></stop>
        </linearGradient>
      </defs>
      <circle cx="120" cy="120" r="120" fill="url(#a)"></circle>
      <path
        fill="#c8daea"
        d="M98 175c-3.888 0-3.227-1.468-4.568-5.17L82 132.207 170 80"
      ></path>
      <path
        fill="#a9c9dd"
        d="M98 175c3 0 4.325-1.372 6-3l16-15.558-19.958-12.035"
      ></path>
      <path
        fill="url(#b)"
        d="M100.04 144.41l48.36 35.729c5.519 3.045 9.501 1.468 10.876-5.123l19.685-92.763c2.015-8.08-3.08-11.746-8.36-9.349l-115.59 44.571c-7.89 3.165-7.843 7.567-1.438 9.528l29.663 9.259 68.673-43.325c3.242-1.966 6.218-.91 3.776 1.258"
      ></path>
    </svg>

            <P style={{marginLeft: "20px"}} size="1em">Hier geht es zum Chat!</P>
</Button>
</Screen4></a>

        
        </Body>  
)
}