// Copyright 2021 andreygorbov
// 
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
// 
//     http://www.apache.org/licenses/LICENSE-2.0
// 
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
import React, {useEffect, useState, useContext, useRef} from 'react';
import styled, { keyframes} from 'styled-components'
import { Lessons } from './lessonlist'
import { fetchCourse, getLessons } from './net'
import { Button } from './ui/button'
import {Text} from './ui/text'
import { AddCourse } from './addcourse'
import axios from 'axios';
import { AiOutlineMenu, AiOutlineCaretLeft } from "react-icons/ai";
import { AddSubscriptions } from './addSubscription';
import { API_URL } from '../var'
import { useOnClickOutside } from '../menuhook'
import {Toggle} from './ui/toggle'
import { Uploader } from './uploader';
import {StoreContext} from '../store/store'

 
const toggleAnima = keyframes`
0% { transform: rotate(0deg)};
100% {transform: rotate(90deg)};
`
const Wrapper = styled.div`
box-sizing: border-box;
direction: ltr;
flex: 1 1 0%;
font-family: 'Ubuntu';
outline: none;
`
const ButtonWrapper = styled.div`
display: flex;
align-items: center;
margin:  5px 0;
justify-content: space-evenly;
`
const Wrapper2 = styled.div`
display: flex;
align-items: center;
margin:  5px 0;
justify-content: space-evenly;
width:100%;
color: ${({theme})=> theme.text};
`

const DelCourse = styled.div`
display: flex;
flex-direction: column;
justify-content:center;
box-sizing:border-box;
padding: 1rem 0 0 1rem;
margin: 2rem 0 0 2rem; 
`
const CursSelector = styled.div`
display: flex;
margin-left:20px;
flex-grow: 1;
`

const CourseSelectorWrapper = styled.div`
box-sizing: border-box;
align-items: center;
display: flex;
flex-wrap: wrap;
justify-content: space-between;
padding: 0.875rem 1.25rem;
position: relative;
border-bottom: 0px;
box-shadow:  rgba(41, 48, 66, 0.1) 0px 0px 2rem 0px;
`
const Toggle1 = styled.div`
color: ${({theme})=> theme.text};
transform: ${({open}) => !open?'rotate(0deg)':'rotate(90deg)'};
&::visited{
animation-name: ${toggleAnima};
animation-duration: 1s;
}
`

const Select = styled.select`
font-size: ${({theme})=> theme.size};
  width: 100%;
  height: 35px;
  background: ${({theme})=> theme.lessonbody};
  color: ${({theme})=> theme.text};;
  padding-left: 5px;
  border: none;
  margin-left: 10px;

  option {
    color: ${({theme})=> theme.text};;
    background-color:${({theme})=> theme.lessonbody};
    display: flex;
    white-space: pre;
    min-height: 20px;
    padding: 0px 2px 1px;
  }
`;

const Greating = styled.div`
display:flex;
align-items: center;
padding: 0.5rem 1em;
justify-content: space-between;
line-height: normal;
@media screen and (max-width: 450px) {
font-size:0.7em;

}
`
const Ava = styled.div`
border-radius: 100%;
min-width:40px;
min-height: 40px;
background-size: contain;
background-repeat: no-repeat;
background-image: url(${({ img })=> img});
`
const Div = styled.div`
display: flex;
align-items: center;
margin: 0 1rem;
`

export const Courses = ({auth, setSelectedTheme }) => {
  const node = useRef();
  const node2 = useRef();
  useOnClickOutside(node, node2, () => setOpen(false));
  const [open, setOpen] = useState(false);
  const [isLoaded, setUpload] = useState(false)
  const [{courses, lessons, courseIndex, selector}, dispatch] = useContext(StoreContext);


    
  useEffect(() => {
      if (auth.idc) {
        setUpload(true)
        async function getCurs() {
          await fetchCourse({ idc: auth.idc }).then(cur => {
              dispatch({ type: 'SET_COURSES', payload: cur })
              if (cur.length !== 0 ) {
               getLessons({ idc: auth.idc, courseId: cur[courseIndex].id }).then(less => {
                if (less.length !== 0) {
                  dispatch({ type: 'SET_LESSONS', payload: less })
                }
              })
            }
          }).finally(() => setUpload(false))
            
        }
          setOpen(false)
          getCurs()
        
        
      }
    }, [auth.idc])
  


  async function getNewCourse() {
    setUpload(true)
    await fetchCourse({ idc: auth.idc }).then(cur => {
cur!==0 && dispatch({type: 'SET_COURSES', payload:cur})
    }).finally(()=> setUpload(false))
    }
 
  async function fetchLesson({ courseId }) {

    setUpload(true)
      await getLessons({ idc: auth.idc, courseId: courseId }).then(less => {
        dispatch({ type: 'SET_LESSONS', payload:less })
      }).finally(()=> setUpload(false))
    
    }
  
  const deleteCourse = async () => {
    setUpload(true)
    await axios.post(API_URL+'/courses', {
        courseId: courses[courseIndex].id,
        action: 'del'
    }).then( () => {
      dispatch({ type: 'SET_COURSES', payload:courses.splice(courseIndex+1, 1) })
      dispatch({ type: 'SET_COURSE_INDEX', payload: 0 })
      dispatch({ type: 'SET_CORSE_EDITOR_SELECTOR', payload:  'course'})
    }).catch(e=> console.log(["Ошибка удаления курса"], e.mesage)).finally(async () => {
      await getNewCourse()
      setUpload(false)
    })
  }
  
  var courseId = courses.length!==0 && courses[courseIndex]?.id
  
    var coursmenu = null
    if (courses) {
      coursmenu = courses.map((item, key) => {
        return (<option key={`${Date.now()}${key}`} value={key}>{item.name} </option>)
      })
    }
      
  return (
  <Wrapper>
    {isLoaded? <Uploader/> :
   <>
   <Greating>
    
    {auth.role==="admin"&&<ButtonWrapper>
        <Button margin='0 0 0 5px' icon="add" onClick={() => dispatch({ type: 'SET_CORSE_EDITOR_SELECTOR', payload:  'add'})} />
              {courses && courses.length !== 0 && <Button margin='0 0 0 5px' icon="edit" onClick={() => dispatch({ type: 'SET_CORSE_EDITOR_SELECTOR', payload: 'curedit' })} />}
              {courses && courses.length !== 0 && <Button margin='0 0 0 5px' icon="del" onClick={() => dispatch({ type: 'SET_CORSE_EDITOR_SELECTOR', payload:  'del'})} />}
        <Button margin='0 0 0 5px' text="sub" onClick={() => dispatch({ type: 'SET_CORSE_EDITOR_SELECTOR', payload:  'sub'})} />
      </ButtonWrapper>}
     <Div>
      <Text margin="0 1rem 0 0">{`Добро пожаловать, ${auth.first_name||auth.username}`}</Text>
        <Ava img={auth.photo_url} />
        </Div> 
      <Toggle relativ setSelectedTheme={setSelectedTheme} id={`${Date.now()}`} />
     
    </Greating>
    <CourseSelectorWrapper size='5px'>

      <Wrapper2>

        <Toggle1 ref={node} onClick={() => setOpen(!open)} open={open} >
        
          <AiOutlineMenu size='2em'/> 
        </Toggle1>
        <AiOutlineCaretLeft size="1em"/>
          <Text margin="0 5px">Уроки</Text>
    <CursSelector>
        <Select name="type" id="select_type"
          value={courseIndex}
          onChange={async e => {
            dispatch({ type: 'SET_COURSE_INDEX', payload: +e.target.value })
            fetchLesson({courseId: courses[+e.target.value].id})
        }}>
       {coursmenu}
        </Select>

        </CursSelector>
        </Wrapper2>
     
    </CourseSelectorWrapper>
    {selector === "course" && courses?.length !== 0 ? <Lessons node2={node2} setOpen={setOpen} open={open} fetchLesson={fetchLesson} lessons={lessons}  auth={auth} courseId={courseId} getNewCourse={ getNewCourse}/>:null}
    {selector === 'sub' && auth.role==="admin"&&<AddSubscriptions auth={auth} />}
    {selector === "add" && auth.role==="admin"&&<AddCourse fetchLesson={fetchLesson} auth={auth}  fetchCourse={fetchCourse}  />}
    {selector === "curedit" && auth.role==="admin"&&<AddCourse fetchLesson={fetchLesson} auth={auth}  fetchCourse={fetchCourse}  />}
    {selector === 'del' && auth.role === "admin" && (<DelCourse>
      <Text type="h2">{`Удалить курс ${courses[courseIndex]?.name}`} </Text>
              <ButtonWrapper><Button onClick={deleteCourse} width="140px" padding="10px">ДА</Button>  <Button padding="10px" width="140px" onClick={() => dispatch({ type: 'SET_CORSE_EDITOR_SELECTOR', payload: 'course' })}>НЕТ</Button> </ButtonWrapper>
    </DelCourse>)}
    </>
    }
      </Wrapper>
  )
}

