// Copyright 2021 andreygorbov
// 
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
// 
//     http://www.apache.org/licenses/LICENSE-2.0
// 
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import React, { useState} from "react";
import styled from "styled-components";
import { FiSun, FiMoon} from "react-icons/fi";
import { useTheme } from '../../useTheme'
import { dark, light } from '../../theme'
import {getFromLS} from '../../storage'

const SwitchInput = styled.input`
  margin: 0;
  height: 0;
  width: 0;
  visibility: hidden;
  display:none;
`;
const Wrapper = styled.div`
position: ${({ relativ })=> relativ?'relativ':'absolute'};
right: 2rem;
top: ${({rotate})=>rotate?'4rem':'1rem'};
width: 80px;
display: flex;
justify-content:space-between;
align-items: center;
z-index: 100;
transform: rotate(${({rotate})=> rotate?'270deg':'0deg'});
`

const SwitchLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 2.2em;
    height: 1.1em;
  border-radius: 100px;
  border: 2px solid ${({color})=> color?color:'grey'};
  position: relative;
  transition: background-color 0.2s;
`;

const SwitchButton = styled.span`
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 0.5em;
  height: 0.5em;
  border-radius: 45px;
  transition: 0.2s;
  background: ${({color})=> color?color:'grey'};
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
  ${SwitchInput}:checked + ${SwitchLabel} & {
    left: calc(100% - 2px);
    transform: translateX(-100%);
  }

  ${SwitchLabel}:active & {
    width: 12px;
  }
`;

export const Toggle = ({ id, relativ, setSelectedTheme, rotate, color}) => {
    const { mytheme, setMode } = useTheme()
    
    const storagetheme = getFromLS('theme')

    let baseThemeSelector
    if (storagetheme === 'light') {
        baseThemeSelector = true
    } else {
        baseThemeSelector = false
    }
    
    const [themeSelector, setThemeSelector] = useState(baseThemeSelector);
   

    
    const themeHandler = (event) => {

        if (event === true) {
            setMode("light")
            setThemeSelector(true)
          setSelectedTheme(light)

        } else {
        setMode("dark")
           setThemeSelector(false)
          setSelectedTheme(dark)

        }
     } 
     

  return (
      <Wrapper relativ={relativ} rotate={ rotate}>
          <FiMoon size="0.8em" color={color?color:'#808080'}/>
      <SwitchInput
        className="switch-checkbox"
        id={id}
        type="checkbox"
        checked={themeSelector}
        onChange={e => themeHandler(!themeSelector)}
      />
      <SwitchLabel color={color} className="switch-label" htmlFor={id}>
        <SwitchButton color={color} className="switch-button" />
          </SwitchLabel>
          <FiSun size="0.8em" color={color?color:'#808080'}/>
          
      </Wrapper>
  );
};

