// Copyright 2021 andreygorbov
// 
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
// 
//     http://www.apache.org/licenses/LICENSE-2.0
// 
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

// Copyright 2021 andreygorbov
// 
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
// 
//     http://www.apache.org/licenses/LICENSE-2.0
// 
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import React from 'react';
import styled from 'styled-components'
import { Video } from "../components/video"
import { Toggle } from '../components/ui/toggle'
import { useInView } from 'react-intersection-observer';




const Body = styled.div`
display: flex;
flex-direction: column;
flex-grow:1;
`

const Screen1 = styled.div`
position: relative;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
height: 100vh;
flex-grow:1;
background-repeat: no-repeat;
background-position: center;
background-size: cover;
background-image: url(${({ img }) => img && img});

`
const Overley = styled.div`
display: flex;
background-color: rgba(0,0,0,0.4);
flex-grow:1;
width:100%;
justify-content: center;
align-items: center;
`
const Screen2 = styled.div`
 position: relative;
margin: 0 0 2rem 0;
display: flex;
justify-content: center;
align-items: center;
flex-grow:1;
flex-wrap:wrap;
box-sizing: border-box;

`
const Screen3 = styled.div`
margin: 6rem 0;
display: flex;
justify-content: center;
align-items: center;
height: 500px;
box-sizing: border-box;
div {
    margin: 100px 0;
    position: relative;
    box-sizing: border-box;
    justify-content: center;
    display: flex;
    width: 80%;
    @media screen and (max-width: 768px) {
    width: 100vw;
  }
}
`

const Sect = styled.div`
width: 60%;
display: flex;
flex-direction: column;
align-items: left;
box-sizing: border-box;
`

const Image = styled.div`
overflow:hidden;
position:relative;
height:${({ big }) => big ? "800px" : "600px"};
min-width: ${({ big }) => big ? "100%" : "450px"};
&::before{
  content: "";
  background-image: url(${({img})=>  img});
  background-size: cover;
    position: absolute;
    background-repeat: no-repeat;
    background-position:center;
    top: 0;
    left: 0;
    width: ${({inView})=> inView?"110%": "100%"};
    height: ${({inView})=> inView?"110%": "100%"};
    transition: width 3s, height 3s;
    transition-timing-function: ease;
}

@media screen and (max-width: 800px) {
    min-width:100%;
  }
`


const Dobro = styled.div`
position:relative;
padding: 0 0 4rem 0;
margin: 0 3rem;
display: flex;
width:50%;
flex-direction: column;
box-sizing: border-box;
height: fit-content;
line-height: 1.4;
text-align:  ${({ revers }) => revers ? 'right' : 'left'};
align-items: ${({ revers }) => revers ? 'flex-end' : 'flex-start'};
@media screen and (max-width: 1200px) {
margin: 0;
margin: 0 1rem;
width:100%;
  }
`
const Gradient = styled.div`
position: absolute;
display: flex;
bottom: 0px;
    width: 100%;
height: 10vh;
left: 0px;
background: linear-gradient(${({theme})=> theme.gradient});

`
const Heder = styled.p`
width:${({revers})=> revers && "min-content"};
white-space: pre-line;
font-family: 'Ubuntu';
font-style: normal;
font-weight: 800;
font-size: 1em;
letter-spacing: 6px;
text-transform: uppercase;
color: ${({theme, color})=> color?color:theme.heder};
@media screen and (max-width: 1000px) {
    font-size: 0.8em;
  }
`

const P = styled.p`
white-space: pre-line;
font-family: 'Ubuntu';
font-style: normal;
font-weight: normal;
font-size: 1.1em;
line-height: 32px;
color: ${({theme})=> theme.text};
@media screen and (max-width: 320px){
  font-size: ${({size}) =>size?size:"0.8em"};
}
@media screen and (max-width: 1000px){
  font-size: ${({size}) =>size?size:"1em"};
}
`

const H1 = styled.p`
white-space: pre-line;
margin: 0;
font-family: 'Lora';
font-style: normal;
font-weight: 600;
font-size: 3em;
text-transform: uppercase;
color: ${({theme, color})=> color?color:theme.text};
@media screen and (max-width: 1000px) {
    font-size: 1.7em;
  }
@media screen and (max-width: 768px) {
    font-size: 1.5em;
  }
`
const H2Line = styled.div`
background-color: ${({theme, color})=> color?color:theme.heder};
height: 2px;
transition: width 1s;
width: ${({inView})=> inView?"30px":"10px"} ;
margin: 0 1em 0 0;
`
const DivG = styled.div`
display: flex;
align-items: center;
`
const Block = styled.div`
width: 80%;
display:flex;
align-items:center;
justify-content:space-between;
margin-bottom: 2rem;
flex-direction: ${({ revers }) => revers ? 'row-reverse' : 'row'};
@media screen and (max-width: 800px) {
flex-direction: column;
align-items: center;
justify-content: center;
padding: 0 20px;
  }
@media screen and (max-width: 1200px) {
  width: 100%;
}
`
const Button = styled.div`
width: 100%;
text-align: center;
align-items: center;
box-sizing: border-box;
justify-content: center;
display: flex;
margin: 4rem auto;
cursor: pointer;
padding: 11px 20px;
//border: solid 1px #fff;
&:hover{
  background-color: ${({theme})=> theme.button.hover};
}
`
const Screen4 = styled.div`
height: 100vh;
display:flex;
justify-content: center;
align-items: center;
`


const H2 = ({ children, color }) => {
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0.5,
    deley: 3000,
    root:null,
    rootMargin:'0px',
  });
return (
  <DivG ref={ref}><H2Line inView={inView} color={color} /> <Heder color={color} bold='400'>{children}</Heder></DivG>
  )
}

const Img = ({ bigImage, img, revers }) => {

  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0.5,
    deley: 3000,
    root:null,
    rootMargin:'0px',
  });
return (
  <Image big={!bigImage} img={img} revers={revers} ref={ref} inView={inView}/>
  )
}

const InfoBlock = ({ img, heder, text, bigheder, revers, hindex }) => {
  
  
  const bigImage = heder !== "" || bigheder !== "" || text !== ""
  

  return (
        <Screen2  img={img} hindex={hindex}> <Block revers={revers}>
          {bigImage && <Dobro revers={revers}>
            {heder && <H2  revers={revers}>{heder}</H2>}
            {bigheder && <H1>{bigheder}</H1>}
        {text && <P>{text}</P>}
          </Dobro>}
      
          {img && <Img  bigImage={bigImage} img={img} revers={revers}>
        
          </Img>}
        </Block>
        </Screen2>
  )
}
  


export const Trip = ({bigimg ,header, h1, blocks, video, invite, setSelectedTheme}) => {

  const blocklist = blocks.map((block, key) => {
    const even = n => !(n % 2);
    const side = even(key)
    return (
      <InfoBlock
        hindex={key}
      key={Date.now()+`${key}`}
      revers={side}
      heder={block.name}
      text={block.text}
      bigheder={block.bigheder}
      img={block.img}
    />
    )})
    return (
        <Body>
        <Toggle id={`${Date.now()}`}  setSelectedTheme={setSelectedTheme} color="#fff"/>
        <Screen1 img={bigimg}>
        <Gradient />
          <Overley>
                
                
                <Sect>
              <H2 color="#FBD784">{header}</H2>
              <H1 color="#fff"margin="2rem 0 2rem 0">{h1}</H1>
                    
                </Sect>

            </Overley>
            </Screen1>
            
           
        {blocklist}

        {video && <Screen3>
          <Video video={video} height="500px" width="100%" />
        </Screen3>}
        {invite!=="" &&
        <a href={invite}> <Screen4>
          <Button>
            <svg xmlns="http://www.w3.org/2000/svg"
              width="60px"
              height="60px"
              viewBox="0 0 240 240">
      <defs>
        <linearGradient id="a" x1="0.667" x2="0.417" y1="0.167" y2="0.75">
          <stop offset="0" stopColor="#37aee2"></stop>
          <stop offset="1" stopColor="#1e96c8"></stop>
        </linearGradient>
        <linearGradient id="b" x1="0.66" x2="0.851" y1="0.437" y2="0.802">
          <stop offset="0" stopColor="#eff7fc"></stop>
          <stop offset="1" stopColor="#fff"></stop>
        </linearGradient>
      </defs>
      <circle cx="120" cy="120" r="120" fill="url(#a)"></circle>
      <path
        fill="#c8daea"
        d="M98 175c-3.888 0-3.227-1.468-4.568-5.17L82 132.207 170 80"
      ></path>
      <path
        fill="#a9c9dd"
        d="M98 175c3 0 4.325-1.372 6-3l16-15.558-19.958-12.035"
      ></path>
      <path
        fill="url(#b)"
        d="M100.04 144.41l48.36 35.729c5.519 3.045 9.501 1.468 10.876-5.123l19.685-92.763c2.015-8.08-3.08-11.746-8.36-9.349l-115.59 44.571c-7.89 3.165-7.843 7.567-1.438 9.528l29.663 9.259 68.673-43.325c3.242-1.966 6.218-.91 3.776 1.258"
      ></path>
    </svg>

            <P style={{marginLeft: "20px"}} size="1em">Присоединиться к чату</P>
</Button></Screen4> 
</a>
        }
        </Body>  
)
}